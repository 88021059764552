import './Auth.scss';
import { useFormik } from 'formik';
import { Button, Paragraph, TextField } from '@payourse/style-guide';
import { ReactComponent as Payourse } from 'assets/payourse.svg';

import { forgotPasswordSchema } from './schema';

const initialValues = {
  email: '',
};

export function ForgotPassword() {
  const handleSubmit = () => {};
  const { errors, getFieldProps, touched } = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: handleSubmit,
  });

  const isDisabled = !!errors.email;

  return (
    <section className="Auth">
      <div className="Auth__container">
        <h1 className="Auth__title">Reset your password</h1>
        <Paragraph size="m" weight="semi-bold">
          Please enter your email address. We will send you an email with
          instructions.
        </Paragraph>
        <div>
          <form>
            <TextField
              className="Auth__field"
              label="Email"
              id="email"
              placeholder="Enter your email address"
              error={touched.email ? errors.email : ''}
              {...getFieldProps('email')}
            />

            <Button
              className="Auth__btn"
              variant="secondary"
              type="submit"
              disabled={isDisabled}
            >
              Send reset instructions
            </Button>
          </form>
        </div>
      </div>

      <div className="Auth__footer">
        <Payourse />
      </div>
    </section>
  );
}
