import { ChangeEvent, createContext, ReactNode, useContext } from 'react';
import { useProfile } from './useProfile';

type ProfileContextType = {
  step: number;
  state: { [key: string]: string };
  incrementStep: () => void;
  decrementStep: () => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: ChangeEvent<HTMLSelectElement>) => void;
};

const ProfileContext = createContext<ProfileContextType>(
  {} as ProfileContextType
);

export function ProfileContextProvider({ children }: { children: ReactNode }) {
  const value = useProfile();
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export function useProfileContext() {
  const context = useContext(ProfileContext);
  return context;
}
