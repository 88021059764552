import { ChangeEvent, useState } from 'react';
import { fields } from '../utils';

export function useProfile() {
  const [step, setStep] = useState(1);
  const [state, setState] = useState(() =>
    Object.values(fields).reduce((acc, field) => {
      Object.keys(field).forEach(field => {
        acc[field] = '';
      });
      return acc;
    }, {} as { [key: string]: string })
  );

  const incrementStep = () => setStep(s => s + 1);

  const decrementStep = () => setStep(s => s - 1);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = event.target;

    const _value = files ? files[0] : value;
    setState(s => ({ ...s, [name]: _value as string }));
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setState(s => ({ ...s, [name]: value }));
  };

  return {
    step,
    state,
    incrementStep,
    decrementStep,
    handleChange,
    handleSelectChange,
  };
}
