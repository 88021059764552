import './styles.scss';
import {
  Button,
  Heading,
  Text,
  TextField,
  SelectField,
} from '@payourse/style-guide';
import Container from './Container';
import { useProfileContext } from '../context';
import { fields } from '../utils';

export function BusinessInformation() {
  const {
    state,
    incrementStep,
    decrementStep,
    handleChange,
    handleSelectChange,
  } = useProfileContext();

  return (
    <Container>
      <div className="Profile__description">
        <Heading
          element="h3"
          size="xl"
          text="Business information"
          className="Profile__title"
        />
        <Text
          size="m"
          weight="normal"
          text="This will be used as your default business details. You can always change this later."
        />
      </div>

      {Object.values(fields.information).map(field => {
        if (field.type === 'select') {
          return (
            <SelectField
              {...field}
              options={field.options!}
              key={field.name}
              value={state[field.name]}
              className="Profile__field"
              onChange={handleSelectChange}
            />
          );
        }
        return (
          <TextField
            {...field}
            key={field.name}
            value={state[field.name]}
            className="Profile__field"
            onChange={handleChange}
          />
        );
      })}

      <div className="Profile__actions">
        <Button variant="outline" onClick={decrementStep}>
          Go back
        </Button>
        <Button variant="secondary" onClick={incrementStep}>
          Customise your exchange
        </Button>
      </div>
    </Container>
  );
}
