import './styles.scss';
import {
  Button,
  Heading,
  Text,
  TextField,
  SelectField,
} from '@payourse/style-guide';
import Container from './Container';
import { fields } from '../utils';
import { useProfileContext } from '../context';

export function AddPayment() {
  const {
    state,
    incrementStep,
    decrementStep,
    handleChange,
    handleSelectChange,
  } = useProfileContext();

  return (
    <Container>
      <div className="Profile__description">
        <Heading
          element="h3"
          size="xl"
          text="Let’s add a payment method"
          className="Profile__title"
        />
        <Text
          size="m"
          weight="normal"
          text="Enter your payment details here. Your customers will pay you and get paid with this payment method. If you don’t see your preffered payment method, click here to request it."
        />
      </div>

      {Object.values(fields.payments).map(field => {
        if (field.type === 'select') {
          return (
            <SelectField
              {...field}
              options={field.options!}
              key={field.name}
              value={state[field.name]}
              className="Profile__field"
              onChange={handleSelectChange}
            />
          );
        }

        return state['payment-method'] === 'bank-transfer' ? (
          <TextField
            {...field}
            key={field.name}
            value={state[field.name]}
            className="Profile__field"
            onChange={handleChange}
          />
        ) : null;
      })}

      <div className="Profile__actions">
        <Button variant="outline" onClick={decrementStep}>
          Go back
        </Button>
        <Button variant="secondary" onClick={incrementStep}>
          Confirm
        </Button>
      </div>
    </Container>
  );
}
