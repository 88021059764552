import './styles.scss';
import { Button, Heading, Text, TextField } from '@payourse/style-guide';
import Container from './Container';
import { fields } from '../utils';
import { useProfileContext } from '../context';

const reviewFields = {
  customise: ['logo', 'description', 'colour'],
  pair: ['pair', 'crypto-asset', 'quantity', 'base-rate'],
  payments: ['payment-method', 'details'],
};

export function ReviewProfile() {
  const { state, decrementStep } = useProfileContext();

  return (
    <Container>
      <div className="Profile__description">
        <Heading
          element="h3"
          size="xl"
          text="Let’s review your exchange"
          className="Profile__title"
        />
        <Text
          size="m"
          weight="normal"
          text="Here are the details of your exchange. Looking nice! Confirm and create your exchange to start buying and selling crypto. You can always make changes anytime!"
        />
      </div>

      <div>
        <Heading
          element="h3"
          size="l"
          text="Customise"
          className="Profile__title"
        />

        <TextField
          {...fields.information['exchange-name']}
          placeholder=""
          value={state['exchange-name']}
          className="Profile__field"
          disabled
        />

        {reviewFields.customise.map(field => {
          if (field === 'logo') {
            return state.logo ? (
              <>
                <label className="Profile__label">Logo</label>
                <div className="Profile__file-preview">
                  <img src={URL.createObjectURL(state.logo)} alt="logo" />
                </div>
              </>
            ) : null;
          }

          return (
            <TextField
              {...fields.customise[field]}
              type="text"
              key={field}
              value={state[field]}
              className="Profile__field"
              placeholder=""
              disabled
            />
          );
        })}
      </div>

      <div>
        <Heading element="h3" size="l" text="Pair" className="Profile__title" />

        {reviewFields.pair.map(field => (
          <TextField
            {...fields.pair[field]}
            key={field}
            value={state[field]}
            placeholder=""
            className="Profile__field"
            disabled
          />
        ))}
      </div>

      <div>
        <Heading
          element="h3"
          size="l"
          text="Payments"
          className="Profile__title"
        />

        <TextField
          {...fields.payments['payment-method']}
          label="Payment method"
          value={state['payment-method']}
          className="Profile__field"
          disabled
        />

        <TextField
          label="Bank details"
          name="bank-details"
          value={`${state['account-name']} - ${state['payment-method']} ~ ${state['bank-name']}`}
          className="Profile__field"
          disabled
        />
      </div>

      <div className="Profile__actions">
        <Button variant="outline" onClick={decrementStep}>
          Go back
        </Button>
        <Button variant="secondary">Launch exchange!</Button>
      </div>
    </Container>
  );
}
