/**
 * Utility funtion to check password strength during registration
 * @param {string} password - Password string
 * @returns {number} Password strength
 */
export const checkPasswordStrength = (password: string) => {
  const uppercaseRegex = /[A-Z]/g;
  const lowercaseRegex = /[a-z]/g;
  const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/g;
  const numberRegex = /[0-9]/g;

  let strength = 0;

  if (uppercaseRegex.test(password)) strength += 15;
  if (lowercaseRegex.test(password)) strength += 10;
  if (specialCharacterRegex.test(password)) strength += 25;
  if (numberRegex.test(password)) strength += 25;
  if (password.length >= 8) strength += 25;

  return strength;
};
