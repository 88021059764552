import './ProfileSetup.scss';
import { ReactSVG } from 'react-svg';
import Steps, { Step } from 'rc-steps';
import 'rc-steps/assets/index.css';

import {
  About,
  BusinessInformation,
  CustomiseExchange,
  AddPayment,
  ReviewProfile,
  AddCryptoPair,
} from './components';

import { ProfileContextProvider, useProfileContext } from './context';

function ProfileSetup() {
  const { step } = useProfileContext();

  return (
    <div className="ProfileSetup">
      {step >= 3 ? (
        <div className="ProfileSetup__logo">
          <ReactSVG src="/logo-sm.svg" />
        </div>
      ) : null}

      <div className="ProfileSetup__container">
        {step < 3 ? (
          <ReactSVG
            src="/logo.svg"
            style={{ textAlign: 'center', marginBottom: 30 }}
          />
        ) : (
          <Steps
            progressDot
            size="default"
            initial={3}
            current={step}
            labelPlacement="horizontal"
          >
            <Step title="Customise" icon="apple" />
            <Step title="Pair" />
            <Step title="Payments" />
            <Step title="Review" />
          </Steps>
        )}

        {step === 1 && <About />}
        {step === 2 && <BusinessInformation />}
        {step === 3 && <CustomiseExchange />}
        {step === 4 && <AddCryptoPair />}
        {step === 5 && <AddPayment />}
        {step === 6 && <ReviewProfile />}
      </div>
    </div>
  );
}

export default function Profile() {
  return (
    <ProfileContextProvider>
      <ProfileSetup />
    </ProfileContextProvider>
  );
}
