import './Auth.scss';
import { useFormik } from 'formik';
import { Button, TextField, Paragraph, Password } from '@payourse/style-guide';
import { ReactComponent as Payourse } from 'assets/payourse.svg';

import { signinSchema } from './schema';

const initialValues = {
  email: '',
  password: '',
};

export function SignIn() {
  const handleSubmit = () => {};
  const { values, errors, getFieldProps, touched } = useFormik({
    initialValues,
    validationSchema: signinSchema,
    onSubmit: handleSubmit,
  });

  const isDisabled = !!(errors.email || errors.password);

  return (
    <section className="Auth">
      <div className="Auth__container">
        <h1 className="Auth__title">
          Sign in to <span>Simpa</span>
        </h1>
        <Paragraph size="m" weight="semi-bold" className="Auth__link">
          Don't have an account? <a href="/signup">Sign up</a>
        </Paragraph>
        <div>
          <form>
            <TextField
              className="Auth__field"
              label="Email"
              id="email"
              placeholder="Enter your email address"
              error={touched.email ? errors.email : ''}
              {...getFieldProps('email')}
            />
            <Password
              className="Auth__field"
              label="Password"
              type="password"
              id="password"
              linkHref="/forgot-password"
              linkText="Forgot password?"
              placeholder="Enter a password"
              autoComplete="current-password"
              error={
                !values.password && touched.password ? errors.password : ''
              }
              {...getFieldProps('password')}
            />

            <Button
              className="Auth__btn"
              variant="secondary"
              type="submit"
              disabled={isDisabled}
            >
              Log in
            </Button>
          </form>
        </div>
      </div>

      <div className="Auth__footer">
        <Payourse />
      </div>
    </section>
  );
}
