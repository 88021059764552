import './styles.scss';
import { Fragment, ChangeEvent, DragEvent, useRef, useState } from 'react';
import { Icon } from '@blueprintjs/core';

import {
  Button,
  Heading,
  Paragraph,
  Text,
  TextField,
} from '@payourse/style-guide';
import Container from './Container';
import { useProfileContext } from '../context';
import { fields } from '../utils';

export function CustomiseExchange() {
  const { state, incrementStep, handleChange } = useProfileContext();
  const [img, setImg] = useState(
    state.logo ? URL.createObjectURL(state.logo) : ''
  );
  const fileRef = useRef<HTMLInputElement>(null);

  const triggerFileInput = () => {
    fileRef.current?.click();
  };

  const handleDragEvent = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleDropEvent = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const files = event.dataTransfer.files;
    const url = URL.createObjectURL(files[0]);
    handleChange({
      target: { name: 'logo', files },
    } as ChangeEvent<HTMLInputElement>);
    setImg(url);
  };

  const handleOnchange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      handleChange(event);
      setImg(url);
    }
  };

  return (
    <Container>
      <div className="Profile__description">
        <Heading
          element="h3"
          size="xl"
          text="Let’s customise your exchange"
          className="Profile__title"
        />
        <Paragraph size="m" weight="regular">
          Add your exchange logo, add a description for your exchange and choose
          a primary colour. You can always change these later
        </Paragraph>
      </div>

      {Object.values(fields.customise).map(field => {
        if (field.type === 'file') {
          return (
            <Fragment key={field.name}>
              <label htmlFor="file-input" className="Profile__label">
                Logo
              </label>
              <input
                ref={fileRef}
                onChange={handleOnchange}
                type="file"
                name="logo"
                id="file-input"
                accept=".jpg, .jpeg, .png"
                className="Profile__logo-input"
              />
              <div
                className="Profile__file-upload"
                onClick={triggerFileInput}
                onDragEnter={handleDragEvent}
                onDragOver={handleDragEvent}
                onDrop={handleDropEvent}
              >
                <Icon icon="document" color="#47b881" iconSize={25} />
                <Heading element="h6" text="Select files to upload" size="s" />
                <Text text="or drag and drop here" weight="normal" size="s" />
                <img src={img} alt="" style={{ width: 200, marginTop: 20 }} />
              </div>
            </Fragment>
          );
        }

        return (
          <TextField
            {...field}
            key={field.name}
            value={state[field.name]}
            className="Profile__field"
            onChange={handleChange}
          />
        );
      })}

      <div className="Profile__actions">
        <Button variant="secondary" onClick={incrementStep}>
          Save and proceed
        </Button>
      </div>
    </Container>
  );
}
