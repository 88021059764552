import * as yup from 'yup';

export const signupSchema = yup.object().shape({
  email: yup.string().email().required(),
  username: yup.string().required(),
  password: yup
    .string()
    .required()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*(),.?":{}|<>]).{8,}$/,
      "Try adding symbols or uncommon words. Don't reuse a password from another site."
    ),
});

export const signinSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
});
