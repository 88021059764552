import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SignUp, SignIn, ForgotPassword } from 'components/Auth';
import BusinessProfileSetup from './components/ProfileSetup';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/profile/setup" element={<BusinessProfileSetup />} />
      </Routes>
    </Router>
  );
}

export default App;
