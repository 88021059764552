import { countries } from 'countries-list';

type Field = {
  label: string;
  id?: string;
  type?: string;
  name: string;
  linkHref?: string;
  linkText?: string;
  className?: string;
  placeholder?: string;
  fieldTitle?: string;
  value?: string;
  options?: { label: string; value: string }[];
};

type Steps = 'about' | 'information' | 'customise' | 'pair' | 'payments';

const countriesOptions = Object.values(countries).map(country => ({
  label: country.name,
  value: country.name,
}));

export const fields: { [key in Steps]: { [key: string]: Field } } = {
  about: {
    'existing-exchange': {
      name: 'existing-exchange',
      label: 'Already running an exchange?',
      type: 'select',
      placeholder: 'Please choose one',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    volume: {
      name: 'volume',
      placeholder: 'Please enter an amount',
      label: 'How much volume do you currently process? (USD)',
    },
  },
  information: {
    name: {
      label: 'Name',
      id: 'name',
      name: 'name',
      placeholder: 'Your full name',
    },
    'exchange-name': {
      label: 'Exchange name',
      id: 'exchange-name',
      name: 'exchange-name',
      placeholder: 'Ajoke Exchange',
    },
    website: {
      label: 'Business or personal website (optional)',
      id: 'website',
      name: 'website',
      placeholder: 'example.com',
    },
    country: {
      label: 'Country/Region',
      type: 'select',
      id: 'country',
      name: 'country',
      placeholder: 'Please select your country',
      options: countriesOptions,
    },
    phone: {
      label: 'Phone number',
      id: 'phone',
      name: 'phone',
      placeholder: 'Your business number',
    },
  },
  customise: {
    logo: {
      label: 'Logo',
      type: 'file',
      name: 'logo',
      id: 'logo',
    },
    description: {
      name: 'description',
      label: 'Description',
      placeholder: 'Buy and sell your crypto with Ajoke exchange',
    },
    colour: {
      name: 'colour',
      type: 'color',
      label: 'Primary colour',
      placeholder: '#00000',
    },
  },
  pair: {
    pair: {
      name: 'pair',
      label: 'Pair type',
      type: 'select',
      placeholder: 'Please choose one',
      options: [
        { label: 'Buy', value: 'buy' },
        { label: 'Sell', value: 'sell' },
      ],
    },

    'crypto-asset': {
      name: 'crypto-asset',
      label: 'Select crypto asset',
      placeholder: 'Please choose one',
      type: 'select',
      options: [
        { label: 'BTC', value: 'BTC' },
        { label: 'USDT', value: 'USDT' },
        { label: 'ETH', value: 'ETH' },
        { label: 'BUSD', value: 'BUSD' },
      ],
    },

    'cash-currency': {
      name: 'cash-currency',
      label: 'Select cash currency',
      placeholder: 'Please choose one',
      type: 'select',
      options: [
        { label: 'NGN', value: 'NGN' },
        { label: 'USD', value: 'USD' },
        { label: 'GHS', value: 'GHS' },
        { label: 'KES', value: 'KES' },
      ],
    },

    quantity: {
      name: 'quantity',
      label: 'Quantity',
      fieldTitle: 'BTC',
      placeholder: 'Set crypto amount',
    },

    'base-rate': {
      name: 'base-rate',
      label: 'Set base rate (BTC/NGN)',
      value: '21,227,162.62',
      placeholder: '0.00',
    },

    'floating-margin': {
      name: 'floating-margin',
      label: 'Floating margin (%)',
      linkHref: '',
      linkText: 'How is this calculated?',
      placeholder: '0.00',
    },
  },
  payments: {
    'payment-method': {
      name: 'payment-method',
      label: 'Select payment method',
      placeholder: 'Please choose one',
      type: 'select',
      options: [
        { label: 'Bank transfer', value: 'bank-transfer' },
        { label: 'Momo', value: 'momo' },
      ],
    },
    'account-name': {
      name: 'account-name',
      label: 'Account name',
      placeholder: "What's the name on your bank account?",
    },
    'account-number': {
      name: 'account-number',
      label: 'Bank account number',
      placeholder: "What's your account number",
    },
    'bank-name': {
      name: 'bank-name',
      label: 'Bank name',
      placeholder: "What's your bank called",
    },
  },
};
