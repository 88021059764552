var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Avatar from './Avatar';
var sizeMap = {
    small: 20,
    medium: 27,
    large: 35,
};
var colors = [
    'neutral',
    'green',
    'blue',
    'red',
    'orange',
    'purple',
    'yellow',
    'teal',
];
var selectColor = function (index) { return colors[(8 + index) % 8]; };
function AvatarStack(_a) {
    var data = _a.data, size = _a.size, fill = _a.fill, _b = _a.maxDisplay, maxDisplay = _b === void 0 ? 4 : _b;
    var itemsToDisplay = data.slice(0, maxDisplay);
    var itemLength = itemsToDisplay.length;
    return (_jsxs("div", __assign({ className: "AvatarStack" }, { children: [itemsToDisplay.map(function (item, index) { return (_jsx("div", __assign({ className: "AvatarStack__container AvatarStack__container--" + size, style: {
                    zIndex: itemLength - index,
                    left: index * sizeMap[size],
                } }, { children: _jsx(Avatar, { text: item, color: selectColor(index), size: size, fill: fill }, void 0) }), void 0)); }), data.length > itemLength ? (_jsx("div", __assign({ className: "AvatarStack__container AvatarStack__container--" + size, style: {
                    zIndex: -1,
                    left: maxDisplay * sizeMap[size],
                } }, { children: _jsx(Avatar, { color: selectColor(maxDisplay), text: data.length - itemLength, size: size, fill: fill }, void 0) }), void 0)) : null] }), void 0));
}
export default AvatarStack;
