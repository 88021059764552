import './Auth.scss';
import React from 'react';
import { useFormik } from 'formik';
import {
  Alert,
  Button,
  PasswordStrengthIndicator,
  TextField,
  Paragraph,
  Password,
} from '@payourse/style-guide';
import { ReactComponent as Payourse } from 'assets/payourse.svg';

import { checkPasswordStrength } from 'utils';
import { signupSchema } from './schema';

const initialValues = {
  email: '',
  username: '',
  password: '',
};

export function SignUp() {
  const handleSubmit = () => {};
  const { values, errors, getFieldProps, touched } = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: handleSubmit,
  });

  const isDisabled = !!(errors.username || errors.email || errors.password);

  return (
    <section className="Auth">
      <div className="Auth__container">
        <h1 className="Auth__title">
          Create your&nbsp;
          <span>Simpa</span>&nbsp;account
        </h1>
        <Paragraph size="m" weight="semi-bold" className="Auth__link">
          Already have an account? <a href="/signin">Sign in</a>
        </Paragraph>
        <div>
          <form>
            <TextField
              className="Auth__field"
              label="Username"
              id="username"
              placeholder="Choose a payourse username"
              error={touched.username ? errors.username : ''}
              {...getFieldProps('username')}
            />
            <TextField
              className="Auth__field"
              label="Email"
              id="email"
              placeholder="you@example.com"
              error={touched.email ? errors.email : ''}
              {...getFieldProps('email')}
            />
            <Password
              className="Auth__field"
              label="Password"
              type="password"
              id="password"
              placeholder="Enter a strong password"
              autoComplete="current-password"
              error={
                !values.password && touched.password ? errors.password : ''
              }
              {...getFieldProps('password')}
            />
            <PasswordStrengthIndicator
              className="Auth__strength-indicator"
              strength={checkPasswordStrength(values.password)}
            />
            {!!values.password && errors.password ? (
              <Alert
                text={errors.password}
                title="Please choose a stronger password"
                level="error"
              />
            ) : null}

            <Button
              className="Auth__btn"
              variant="secondary"
              type="submit"
              disabled={isDisabled}
            >
              Sign up
            </Button>
          </form>
        </div>
      </div>
      <div className="Auth__footer">
        <Payourse />
      </div>
    </section>
  );
}
