import './styles.scss';
import { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
}

function Container({ children }: ContainerProps) {
  return <section className="Container">{children}</section>;
}

export default Container;
